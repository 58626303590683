#ChartData {}

#ChartData .white-cntainer {
    background-color: white;
    border-radius: 10px;
    padding: 10px 25px;
}


#ChartData .heading-box {
    /* background-color: #0D349B;
    color: white;
    border-radius: 10px;
    padding: 15px 25px; */


    background-color: #0D349B;
    color: white;
    border-radius: 10px;
    /* padding: 15px 25px; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: start;
    width: 150px;
    height: 85px;
}

#ChartData .heading-box h5{
    width: 100px;
    font-size: .8rem;
    margin: 0;
    text-align: center;
}



