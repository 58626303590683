#ProjectDashboard{
    background-color: #EEF7FF;
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 30px;
}

#ProjectDashboard label{
    font-size: 0.8rem;
    font-weight: 500;
}


